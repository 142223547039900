<template>
    <modal ref="modalDescargarCatalogo" titulo="Descargar catálogo de productos" :no-aceptar="true" adicional="Descargar" :cargado-modal="cargandoPdf" @adicional="comprobar">
        <div class="row mx-0 justify-center">
            <div v-if="!leechero" class="col-10">
                <p class="f-15 text-general mb-3">
                    {{ cedi ? 'Seleccione leechero' :'Seleccione cedis y leechero' }}
                </p>
                <el-select v-if="!cedi" v-model="id_cedis" placeholder="Seleccione el cedis" class="w-100">
                    <el-option
                    v-for="item in cedisSinTodos"
                    :key="item.id"
                    :label="item.nombre"
                    :value="item.id"
                    />
                </el-select>
                <el-select v-model="id_leechero" placeholder="Seleccione el leechero" filterable class="w-100 my-2">
                    <el-option
                    v-for="item in leecheros"
                    :key="item.id"
                    :label="item.propietario_nombre"
                    :value="item.id"
                    />
                </el-select>
            </div>
            <div class="col-10 my-3">
                <p class="text-general f-15">
                    Seleccione la vigencia
                </p>
                <div class="row mx-0 mt-3">
                    <el-date-picker
                    v-model="tiempo_vigencia"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    type="daterange"
                    unlink-panels
                    :disabled="leechero"
                    popper-class="date-picker-edit-popper"
                    start-placeholder="Fecha inicio"
                    end-placeholder="Fecha fin"
                    />
                </div>
            </div>
            <div class="col-10 my-3">
                <p class="text-general f-15">
                    Origen de los productos.
                </p>
                <el-radio v-model="origen" class="radio-general" :label="1">
                    A la venta
                </el-radio>
                <el-radio v-model="origen" class="radio-general" :label="2">
                    Todos
                </el-radio>
            </div>
            <div class="col-10 my-3">
                <p class="text-general f-15">
                    Orden en que saldrán las categorias.
                </p>
                <el-radio v-model="ordenar" class="radio-general" :label="1">
                    Alfabético
                </el-radio>
                <el-radio v-model="ordenar" class="radio-general" :label="2">
                    Cantidad productos
                </el-radio>
            </div>
            <div class="col-10 my-3">
                <p class="text-general f-15">
                    Añadir al detalle del producto
                    <el-tooltip class="item" effect="light" placement="bottom">
                        <template slot="content">
                            <p class="text-general2 f-600 f-12">Sin etiqueta:</p>
                            <p class="text-general2 f-12">El valor del producto no incluye IVA</p>
                            <p class="text-general2 f-600 f-12">Con etiqueta "+ IVA":</p>
                            <p class="text-general2 f-12">El valor del producto no incluye IVA y adicional lleva una etiqueta que dice: + IVA</p>
                            <p class="text-general2 f-600 f-12">Con Etiqueta "IVA Incluido":</p>
                            <p class="text-general2 f-12">El valor del producto incluye el IVA y adicional lleva una etiqueta que dice: IVA incluido</p>
                        </template>
                        <i class="icon-info-circled-alt f-16 text-general" />
                    </el-tooltip>
                </p>
                <div class="row mx-0 mb-2 mt-3">
                    <el-radio v-model="etiqueta" class="radio-general" :label="1">
                        Sin etiqueta
                    </el-radio>
                </div>
                <div class="row mx-0 mb-2">
                    <el-radio v-model="etiqueta" class="radio-general" :label="2">
                        Con etiqueta "+ IVA"
                    </el-radio>
                </div>
                <div class="row mx-0 mb-2">
                    <el-radio v-model="etiqueta" class="radio-general" :label="3">
                        Con Etiqueta "IVA Incluido"
                    </el-radio>
                </div>
            </div>
            <div v-if="id_cedis != null || leechero" class="col-10">
                <p class="text-general f-15">
                    Seleccione las categorías que aparecerán <br />
                    En paréntesis la cantidad de productos por categoría
                </p>
                <div class="row mx-0">
                    <el-checkbox v-model="todos" :indeterminate="isIndeterminate" class="check-red" @change="cambiarCategorias">
                        Seleccione todos
                    </el-checkbox>
                </div>
                <div v-for="(data,d) in categoriasOrden" :key="d" class="row mx-0 mt-2">
                    <el-checkbox v-model="data.checked" class="check-red" @change="cambiarCategoria">
                        {{ data.nombre }} ({{ data.productos_count }})
                    </el-checkbox>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Catalogo from "~/services/configurar/admin/catalogoProductos"
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
    data(){
        return {
            tiempo_vigencia: [
                moment().startOf('week').toDate(),
                moment().endOf('week').toDate(),
            ],
            todos: false,
            cedi: false,
            isIndeterminate: false,
            id_cedis: null,
            leechero: false,
            id_leechero: null,
            ordenar: 1,
            etiqueta: 1,
            origen: 1,
            cargandoPdf: false,
            leecheros: [],
            categorias: []
        }
    },
    computed: {
        ...mapGetters({
            cedis:'cedis/cedis',
        }),
        cedisSinTodos(){
            return this.cedis.filter(el => el.id != 0)
        },
        categoriasOrden(){
            if (this.ordenar == 1){
                return _.orderBy(this.categorias,'nombre','asc')
            } else {
                return _.orderBy(this.categorias,'productos_count','desc')
            }
        },
    },
    watch: {
        id_cedis(val){
            if (val == null) return
            this.get_leecheros()
            this.get_categorias()
        },
        // id_leechero(val){
        //     if (val == null) return
        //     this.get_categorias()
        // }
    },
    methods: {
        toggle(params){
            if(params){
                this.id_cedis = params.id_cedis
                this.cedi = params.cedis
                this.id_leechero = null
                if (params.leechero){
                    this.leechero = params.leechero
                    this.id_leechero = params.id_leechero
                }
            }
            this.$refs.modalDescargarCatalogo.toggle();
        },
        async get_leecheros(){
            try {
                const { data } = await Catalogo.get_leecheros(this.id_cedis)
                this.leecheros = data.tenderos
            } catch (e){
                this.error_catch(e)
            }
        },
        async get_categorias(){
            try {

                let datos = {
                    id: this.id_leechero == null ? this.id_cedis : this.id_leechero,
                    tipo: this.id_leechero == null ? 1 : 2,
                }

                const { data } = await Catalogo.get_categorias(datos)
                console.log(data);
                this.categorias = data.categorias

            } catch (e){
                this.error_catch(e)
            }
        },
        async descargar(datos, params){
            try {
                this.cargandoPdf = true

                const {data} = await Catalogo.catalogo(datos)

                if(data.exito){
                    this.notificacion('Exito', 'Se ha generado tu catálogo y se envió a su correo', 'success')
                }

            } catch (e){
                this.error_catch(e)
            }finally {
                this.cargandoPdf = false
                this.$refs.modalDescargarCatalogo.toggle();
            }
        },
        async comprobar(){
            try {

                let ids_categorias = this.categorias.filter(el => el.checked).map(({id}) => (id))
                let datos = {
                    id: this.id_leechero == null ? this.id_cedis : this.id_leechero,
                    tipo: this.id_leechero == null ? 1 : 2,
                    fecha_inicio: moment(this.tiempo_vigencia[0]).format('Y-MM-DD'),
                    fecha_fin: moment(this.tiempo_vigencia[1]).format('Y-MM-DD'),
                    ids_categorias,
                    ordenar: this.ordenar,
                    etiqueta: this.etiqueta,
                    origen: this.origen,
                    id_user: this.$usuario.id
                }
                if(!datos.id) return this.notificacion('', 'Debe seleccionar', 'error')
                const {data} = await Catalogo.comprobar(datos)
                if (data.configurar == true){
                    this.notificacion('', data.mensaje, 'warning')
                    return
                }else{
                    this.descargar(datos, data.parametros)
                }
            } catch (error){
                this.error_catch(error)
            }
        },
        cambiarCategorias(){
            console.log("aquí entra!!!");
            this.categorias.map(el => el.checked = this.todos)
            this.isIndeterminate = false;
            // this.categorias.map(el => el.checked = !el.checked)
        },
        cambiarCategoria(){
            let cheked  = this.categorias.filter(el => el.checked == true).length
            this.isIndeterminate = cheked > 0 && cheked < this.categorias.length;
            // this.categorias.map(el => el.checked = !el.checked)
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
