import axios from 'axios'

const request = axios.create({
    baseURL: process.env.VUE_APP_CATALOGO_URL,
    headers:{
        proyecto:process.env.VUE_APP_PROYECTO_NAME,
    }
})

const catalogoProductos = {
    get_leecheros: (id_cedis) => axios.get(`catalogo-productos/get-leecheros/${id_cedis}`),
    get_categorias: (params) => axios.get(`catalogo-productos/get-categorias`, { params }),
    catalogo: (params) => request({
        method: 'GET',
        url: 'catalogo/generar',
        params,
    }),
    comprobar: (params) => axios.get(`comprobar-catalogo`, { params }),
    get_catalogo_productos: (tipo) => axios.get(`catalogo-productos/get-catalogo-productos/${tipo}`),
    crear_catalogo_productos: datos => axios.post("catalogo-productos/crear-catalogo-productos", datos)
};
export default catalogoProductos
